/**
 * A wrapper around IndexedDB to simplify usage.
 * @param T The type of the record(s) stored.
 */
export class IndexedDbRepository<T> {
  private dbName: string;
  private collectionName: string;
  private primaryKeyName: string;

  constructor(dbName: string, collectionName: string, primaryKeyName: string) {
    this.dbName = dbName;
    this.collectionName = collectionName;
    this.primaryKeyName = primaryKeyName;
  }

  public async openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      // will create the database on the fly if not yet present
      const request = indexedDB.open(this.dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;

        // create object store on the fly if not yet present
        if (!db.objectStoreNames.contains(this.collectionName)) {
          db.createObjectStore(this.collectionName, { keyPath: this.primaryKeyName });
        }
      };

      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onerror = (event) => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  public async read(primaryKeyValue: string): Promise<T> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readonly');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.get(primaryKeyValue);

      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result as T);
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  public async add(record: T): Promise<string> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readwrite');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.add(record);

      request.onsuccess = () => {
        resolve('Record added successfully');
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  public async update(record: T): Promise<string> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readwrite');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.put(record);

      request.onsuccess = () => {
        resolve('Record updated successfully');
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  public async delete(primaryKeyValue: string): Promise<string> {
    const db = await this.openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.collectionName, 'readwrite');
      const collection = transaction.objectStore(this.collectionName);
      const request = collection.delete(primaryKeyValue);

      request.onsuccess = () => {
        resolve('Record deleted successfully');
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
}
