/* eslint-disable no-use-before-define */

import { z } from 'zod';

import { Api } from '~/api-client';

export interface Inventory {
  allergies: Allergy[];
  discountEvents: DiscountEvent[];
  menus: Menu[];
  preparationChoicesById: Record<number, PreparationChoice>;
  /** dictionary for resolving a Product by its id; accessible only after `isInventoryLoaded` has become `true` */
  productsById: Record<number, Product>;
  productTags: ProductTag[];
  hasProductsWithStock: boolean;
  promoProducts: Product[];
  promoProductsName: string | undefined;
  sections: Section[];
  sectionsById: Record<number, Section>;
  allowedSearchableProducts: Product[];
}

export type PreparationChoice = Omit<Api.PreparationChoice, 'options'> & {
  options: Api.PreparationChoiceOption[];
};

export const productTypes = z.enum([
  'ingredient-choice',
  'ingredient',
  'menu',
  'product-customizable',
  'product-simple',
  'supplement-from-collection',
  'supplement',
]);
/**
 * The Product table/entity is used for many types of products.
 * The `type` property on a `Product` will help to differentiate between them.
 *
 * - `ingredient-choice` - Part of the customizable product where the customer __must__ choose between multiple options (like the type of bread), potentially with an added or reduced price.
 * - `ingredient` - Part of a customizable product, already included in the price.
 * - `menu` - Used for the `menuProduct` in the `menu` type, defines the name and the (base) price of the menu.
 * - `product-customizable` - A product with options in any of the following categories: preparation, product, extra, supplement.
 * - `product-simple` - A product without any options.
 * - `supplement-from-collection` - Extra product that can be added to a customizable product, potentially with an added or reduced price, from a predefined `ProductCollection`.
 * - `supplement` - Extra product that can be added to a customizable product, potentially with an added or reduced price.
 */
export type ProductType = z.infer<typeof productTypes>;

export type Product = Omit<
  Api.ProductData,
  | 'id' // Explicitly mentioned to add the JSDoc comment
  | 'vatId'
  | 'depositId'
  | 'defaultSupplements'
  | 'ingredients'
  | 'description'
  | 'nutritionalValues'
  | 'preparationChoices'
  | 'productChoices'
  | 'productCollections'
> & {
  /** The ID of the __product__ OR  __menu__ - when the product-type is `menu`. */
  id: Api.ProductData['id']; // Explicitly mentioned to add the JSDoc comment
  description: string | null;
  nutritionalValues: string | null;
  discountedPrice?: number;
  vatPrice: number;
  depositPrice: number;
  deposit?: Api.Deposit;
  vat?: Api.VATData;
  subSection?: SubSection;
  defaultSupplements: DefaultSupplement[];
  ingredients: IngredientProduct[];
  preparationChoices: ExtraPreparationChoice[];
  productChoices: ExtraProductChoice[];
  productCollections: ExtraProductCollection[];
  tags: ProductTag[];
  allergies: Allergy[];
  discountEvent?: DiscountEvent;
  menu: Menu[];
  type: ProductType;
  isUpgradableToMenu: boolean;
  isPromoProduct: boolean;
  isSearchable: boolean;
};
export type SubSection = Omit<Api.OrderSubSectionData, 'productIds'> & {
  products: Product[];
};
export type Section = Omit<Api.OrderSectionData, 'subSections'> & {
  subSections: SubSection[];
};
export type ExtraProductOrSupplement = Omit<Api.ExtraProductOrSupplement, 'productId'> & {
  product: Product;
};
export type ProductCollection = Omit<Api.ProductCollection, 'extraProducts'> & {
  extraProducts: ExtraProductOrSupplement[];
};
export type ExtraProductCollection = Omit<Api.ExtraProductCollection, 'productCollectionId'> & {
  productCollection: ProductCollection;
};
export type DefaultSupplement = Omit<Api.DefaultSupplement, 'productId'> & {
  product: Product;
};
export type IngredientProduct = Omit<Api.Ingredient, 'ingredientProductId'> & {
  ingredientProduct: Product;
};
export type ExtraPreparationChoice = Omit<Api.ExtraPreparationChoice, 'preparationChoiceId'> & {
  extraPreparationChoice: PreparationChoice;
};
export type ProductChoiceOption = Omit<Api.ProductChoiceOption, 'productId'> & {
  product: Product;
};
export type ProductChoice = Omit<Api.ProductChoice, 'options'> & {
  options: ProductChoiceOption[];
};
export type ExtraProductChoice = Omit<Api.ExtraProductChoice, 'productChoiceId'> & {
  extraProduct: ProductChoice;
};
export type ProductTag = Omit<Api.ProductTag, 'productIds'> & {
  products: Product[];
};
export type Allergy = Omit<Api.Allergy, 'productIds'> & {
  products: Product[];
};
export type DiscountEvent = Omit<Api.DiscountEvent, 'productIds' | 'type'> & {
  type: DiscountType;
  products: Product[];
};
export type Menu = {
  defaultProduct: Product;
  /** Holds the `name` and `price` of the menu. */
  menuProduct: Product;
  collections: ProductCollection[];
};
export type DiscountType = Api.DiscountType;
