import { useCallback } from 'react';

import { Product } from '~/hooks/useInventory/Inventory.typings';

import { useCart } from './useCart';
import { useInventory } from './useInventory';

/*
 * Invariant lower and upper limit for stock capacity
 */
const stockCapacity = { zero: 0, unlimited: Number.MAX_SAFE_INTEGER } as const;

interface Stock {
  availableCapacity: number;
}

/**
 * Custom hook for evaluating current stock capacity, to assess whether a product is available for ordering.
 */
export function useStockEvaluation() {
  // #region private members

  const { productsById, isInventoryLoaded } = useInventory();
  const { totalCountByProductId: totalProductCountInCart } = useCart();

  /**
   * Supporting method: resolve a product from its id
   */
  const resolveProduct = useCallback(
    (productOrProductId: Product | number) => {
      // product is passed: return as-is
      if (typeof productOrProductId === 'object') return productOrProductId;

      // account for early-phase race condition
      if (!isInventoryLoaded) return { hasStock: false } as Product;

      // productId is passed: lookup associated product from inventory
      const product = productsById[productOrProductId];
      if (!product) throw new Error(`No product found for id '${productOrProductId}'`);

      return product;
    },
    [isInventoryLoaded, productsById],
  );

  /**
   * Returns the Stock of one SKU (stock keeping unit)
   * Note:
   * We don't use the `product.isInStock` as it is redundant towards `product.remainingStockCount`
   * - remainingStockCount >= 1: product is in stock
   * - remainingStockCount == 0: product is out of stock
   * - remainingStockCount == -1: pro forma value returned by api when `hasStock` is false
   */
  const readSKU = useCallback(
    (product: Product): Stock => {
      // if a product is not subjected to stock restrictions, it is available by definition
      if (!product.hasStock) return { availableCapacity: stockCapacity.unlimited };

      // from the product's remaining stock count as obtained from the inventory call, subtract the items in the cart (over all orders, products and menus)
      const leftOverCount = product.remainingStockCount - totalProductCountInCart(product.id);

      return leftOverCount >= stockCapacity.zero
        ? { availableCapacity: leftOverCount }
        : { availableCapacity: stockCapacity.zero };
    },
    [totalProductCountInCart],
  );

  /**
   * Returns Stock for an orderable entity.
   * Current in-scope orderable entities: a menu, a (regular) product, a child product.
   */
  const evaluateStock = useCallback(
    (productOrProductId: Product | number): Stock => {
      const product = resolveProduct(productOrProductId);

      // Pathway 1 of 3: Menu; its `defaultProduct` determines the Stock's availableCapacity
      if (product.type === 'menu') {
        return evaluateStock(product.menu[0].defaultProduct); // recursive call
      }

      // obtain the product's childProducts (modifiers), if any
      const childProducts = product.childIds?.map((id) => resolveProduct(id)).filter(Boolean) ?? [];

      // Pathway 2 of 3: no childProducts, so the product itself determines the Stock's availableCapacity
      if (childProducts.length === 0) {
        return readSKU(product);
      }

      // Pathway 3 of 3: the childProducts determine the Stock's availableCapacity
      const evaluationResults = childProducts.map((cp) => readSKU(cp));

      // inspect the (1..n) results: the one with the highest availableCapacity wins
      const prevalentEvaluationResult = evaluationResults.reduce(
        (acc, curr) => ({
          availableCapacity: Math.max(curr.availableCapacity, acc.availableCapacity),
        }),
        /* acc seed:*/ { availableCapacity: stockCapacity.zero },
      );

      return prevalentEvaluationResult;
    },
    [readSKU, resolveProduct],
  );

  // #endregion

  // #region public members

  /**
   * Assess whether the product cannot be provided by the stock (anymore).
   * Supports menus as well, also evaluates availability of child products (modifiers).
   */
  const isOutOfStock = useCallback(
    (productOrProductId: Product | number): boolean => {
      // note: although `===` theoretically suffices, we compare by `<=` for supporting capacity underflows
      return evaluateStock(productOrProductId).availableCapacity <= stockCapacity.zero;
    },
    [evaluateStock],
  );

  /**
   * The opposite of `isOutOfStock`.
   */
  const isInStock = useCallback(
    (productOrProductId: Product | number): boolean => {
      return !isOutOfStock(productOrProductId);
    },
    [isOutOfStock],
  );

  /**
   * Returns in-stock products first, out-of-stock products last.
   */
  const sortByInStockness = useCallback(
    (products: Product[]) => {
      // note: products that are out-of-stock but already in the cart are displayed first as well
      const firstProducts = products.filter((p) => isInStock(p) || totalProductCountInCart(p.id) > 0);
      const lastProducts = products.filter((p) => !firstProducts.includes(p));
      return [...firstProducts, ...lastProducts];
    },
    [isInStock, totalProductCountInCart],
  );

  // #endregion

  return { isInStock, isOutOfStock, sortByInStockness };
}
