export const createTestFieldId = (namespace: string, fieldName: string) => {
  return `${namespace}__${fieldName}`;
};

/* Generate test field id for e2e tests  */
const useTestField = (namespace: string) => {
  return {
    define: (fieldName: string) => ({
      'data-testid': createTestFieldId(namespace, fieldName),
    }),
  };
};

export default useTestField;
