import { createContext, Dispatch, SetStateAction } from 'react';
import { DeepPartial } from 'react-hook-form';

import { type Api } from '~/api-client';

import { AppContext } from './appContext.schema';
import { emptyAppContext } from './emptyAppContext.constant';

export interface ApiBaseHeaders {
  [key: string]: string | undefined | null;
  readonly secApplicationId: string;
}
export interface ApiKioskHeaders extends ApiBaseHeaders {
  readonly deviceId: string;
}
export interface ApiNonKioskHeaders extends ApiBaseHeaders {
  readonly clientGuid: string;
}
export type ApiHeaders = ApiKioskHeaders | ApiNonKioskHeaders;

export type RenderTarget = 'kiosk' | 'desktop' | 'mobile' | null;

export interface AppContextValue {
  appContext: AppContext;
  setAppContext: Dispatch<SetStateAction<AppContext>>;
  resetOrderForCustomer: () => void;
  setFromStartup: (data: Api.StartupResult, overwriteContext?: DeepPartial<AppContext>) => void;
  setFromSession: (data: Api.SessionResult) => void;
  /** Headers for API credentials. Uses `clientGuid` for non-kiosk mode and `deviceId` for kiosk mode. */
  headers?: ApiHeaders | undefined;
  renderTarget: RenderTarget;
}

const createEmptyFn = (fnName: string) => () => {
  // eslint-disable-next-line no-console
  console.warn(`AppContext.${fnName} called before initialized`);
};

export const AppContextContext = createContext<AppContextValue>({
  appContext: emptyAppContext,
  setAppContext: createEmptyFn('setAppContext'),
  resetOrderForCustomer: createEmptyFn('resetOrderForCustomer'),
  setFromStartup: createEmptyFn('setFromStartup'),
  setFromSession: createEmptyFn('setFromSession'),
  headers: undefined,
  renderTarget: 'kiosk',
});

AppContextContext.displayName = 'AppContext';
