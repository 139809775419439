import { addDays, format, Locale, startOfWeek } from 'date-fns';
import { de, enUS, es, fr, it, nl } from 'date-fns/locale';
import i18n from 'i18next';

import { Api } from '~/api-client';

/**
 *  @description Normalize the string representation of the 'timeIndex' to "HHmm" in case it is "Hmm"
 *  @example "810" → "0810", or 810 → "0810"
 */
const normalizeTimeIndexString = (timeIndex: string | number): string => {
  const fixedLength = 4;
  return timeIndex.toString().padStart(fixedLength, '0');
};

/**
 * @description Convert a datetime to a so called 'timeIndex', that is: an integer representing HHmm.
 * @example "Tue Apr 02 2024 15:10:06 GMT+0200" → 1510
 */
export const convertToTimeIndex = (dateTime: Date, extraMinutes?: number): number => {
  const timeIndexMultiplier = 100;

  if (extraMinutes) {
    const minutesToMillisecs = 60000;
    dateTime = new Date(dateTime.getTime() + extraMinutes * minutesToMillisecs);
  }

  return dateTime.getHours() * timeIndexMultiplier + dateTime.getMinutes();
};

/**
 * @description Convert a so called 'timeIndex' to a TimeMoment representation.
 * @example "1510" or 1510 → {hour: 15, minute: 10, timeIndex: 1510}
 */
export const convertToTimeMoment = (timeIndex: string | number): Api.TimeMoment => {
  const timeIndexNormalized = normalizeTimeIndexString(timeIndex);

  const decimalRadix = 10;
  const hours = parseInt(timeIndexNormalized.slice(0, 2), decimalRadix);
  const minutes = parseInt(timeIndexNormalized.slice(2), decimalRadix);
  const slotIndex = parseInt(timeIndexNormalized, decimalRadix);

  return { hour: hours, minute: minutes, timeIndex: slotIndex };
};

/**
 * @description Convert a TimeMoment to a "HH:mm" representation.
 * @example {hour: 15, minute: 10, timeIndex: 1510} → "15:10"
 */
export const convertToTimeString = (timeMoment: Api.TimeMoment): string => {
  return `${timeMoment.hour.toString().padStart(2, '0')}:${timeMoment.minute.toString().padStart(2, '0')}`;
};

/** for mapping an i18n language to a dateFns locale */
const dateFnsLocales: { [i18nLanguage: string]: Locale } = {
  'en-US': enUS,
  'nl-NL': nl,
  'fr-FR': fr,
  'de-DE': de,
  'it-IT': it,
  'es-ES': es,
};

/** converts `dayNumber` to a weekday name in the current i18n language, where `dayNumber` 1 represents 'Monday' */
export const getWeekdayName = (dayNumber: number) => {
  const startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  return format(addDays(startDate, dayNumber - 1), 'EEEE', { locale: dateFnsLocales[i18n.language] || nl });
};

// Utility function to capitalize the first letter of a string.
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @description For each distinct key, sum the values of all items having that key.
 * @param items The items to process.
 * @param keyFn A function for getting the key (string) of an item.
 * @param valueFn A function for getting the value (number) of an item.
 * @returns A dictionary with distinct keys and the sum of the values of all items having that key
 * @example in : [{ foo: "A", bar: 1.2 }, { foo: "B", bar: 1.9 }, { foo: "A", bar: 3.8 }]
 *          out: { "A":5.0, "B":1.9 }
 */
export const sumValuesByKey = <T>(
  items: T[],
  keyFn: (item: T) => string,
  valueFn: (item: T) => number,
): { [key: string]: number } => {
  return items.reduce(
    (acc, curr) => {
      const key = keyFn(curr);
      acc[key] = (acc[key] ?? 0) + valueFn(curr);
      return acc;
    },
    {} as { [key: string]: number },
  );
};
