import './styles/global.css';

import * as Sentry from '@sentry/react';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import classNames from 'classnames';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import Snackbar from './components/UI/Molecules/Snackbar/Snackbar';
import { env } from './env';

const ms = 1000;
const secs = 60;
const mins = 60;
const hours = 24;
const days = 7;

const maxAge = ms * secs * mins * hours * days;

const staleTimeInMinutes = 5;
const defaultStaleTime = ms * secs * mins * staleTimeInMinutes;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: defaultStaleTime,
      cacheTime: maxAge,
    },
    mutations: {
      onError: (err) => {
        Sentry.captureException(err);
      },
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

/** note: in addition to classes here, check also global.css for Snackbar styling */
const bottomCenterSnackBarClasses = classNames(
  /* position: */ '!bottom-[88px] md:!bottom-[264px] lg:!bottom-[10px]',
  /* width: */ 'min-w-[calc(100%_-_24px)] md:min-w-[calc(100%_-_40px)]',
  /* other: */ '!z-[999]',
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister, buster: env.VITE_APP_VERSION, maxAge }}
    >
      {/* DateFNS LocalizationProvider */}
      <SnackbarProvider
        Components={{
          success: Snackbar,
          danger: Snackbar,
          warning: Snackbar,
          info: Snackbar,
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={5000}
        classes={{
          containerAnchorOriginBottomCenter: bottomCenterSnackBarClasses,
        }}
        maxSnack={3}
        preventDuplicate
      >
        <App />
      </SnackbarProvider>

      {/*
      Debugging tool for React Query
      <ReactQueryDevtools initialIsOpen={false} panelPosition="top" position="top-left" /> */}
    </PersistQueryClientProvider>
  </StrictMode>,
);
