import { useCallback, useMemo } from 'react';
import { generatePath, matchPath, NavigateOptions, PathParam, useLocation, useNavigate } from 'react-router-dom';

import { useAppContext } from '~/hooks/useAppContext';
import { useInventory } from '~/hooks/useInventory';
import routes from '~/routes';

type RouteKey = keyof typeof routes;
type RouteValue = (typeof routes)[RouteKey];
type RouteParamKey<T extends string> = PathParam<T>;
type RouteParams<T extends string> = Record<RouteParamKey<T>, string>;
type RouteParamsWithoutMode<T extends string> = Omit<Record<RouteParamKey<T>, string | null>, 'mode'>;

/**
 * useAppRouting hook, contains functions to navigate to different parts of the app
 */
export const useAppRouting = () => {
  const {
    appContext: {
      client: { clientSlug, hasBuyNowTakeLater, hasEatIn, hasTakeOutRegular, hasTakeOutCustom },
      customer: { runtimeMode },
      device: { isKiosk },
    },
  } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { sections, promoProducts } = useInventory();

  const hasMultipleSections = useMemo(() => sections && sections.length > 1, [sections]);

  const shouldSkipSectionList = useMemo(
    () => (sections && sections.length > 0 ? promoProducts.length === 0 || sections.length === 1 : false),
    [promoProducts?.length, sections],
  );

  /** The router `mode` ('k' / 'm' / 's') according to current appContext state */
  const getCurrentMode = useCallback(() => {
    switch (runtimeMode) {
      case null: // `null` should never occur, but tolerated for unforeseen egde cases.
      case 'regular':
        return isKiosk ? 'k' : 'm';
      case 'scanAndGoInAppPayment':
      case 'scanAndGoRelayPayment':
        // For now, stick to 'k' for when on a SOU
        // TODO (TSO-575): Consult expounding on ambiguity of term "kiosk" (hence: ambiguity of '/k') in comment July 12th in TSO-575.
        return isKiosk ? 'k' : 's';

      default:
        throw new Error('Unexpected runtimeMode encountered');
    }
  }, [isKiosk, runtimeMode]);

  const generatePathWithMode = useCallback(
    <T extends RouteValue>(route: T, params: RouteParamsWithoutMode<T>) => {
      const routeParams = (
        route.startsWith('/:mode') ? { ...params, mode: getCurrentMode() } : params
      ) as RouteParams<T>;
      return generatePath(route, routeParams);
    },
    [getCurrentMode],
  );

  const navigateToPath = useCallback(
    <T extends RouteValue>(
      route: T,
      params: RouteParamsWithoutMode<T>,
      navigateOptions?: NavigateOptions,
      searchParams?: URLSearchParams,
    ) => {
      if (params) {
        const searchParamsSuffix = searchParams ? `?${searchParams.toString()}` : '';
        navigate(generatePathWithMode(route, params) + searchParamsSuffix, navigateOptions);
      } else {
        navigate(route, navigateOptions);
      }
    },
    [generatePathWithMode, navigate],
  );

  /** Navigate to sectionlist route or, if the user has nothing to choose there: to the first section route directly.
   * @param isNavigationByApp if the navigation was called for by our app instead of the user, it won't be stored in history.
   */
  const navigateToProducts = useCallback(
    (isNavigationByApp = false) => {
      shouldSkipSectionList
        ? navigateToPath(
            routes.section,
            { clientSlug: clientSlug, sectionId: sections[0].id.toString() },
            { replace: isNavigationByApp },
          )
        : navigateToPath(routes.sectionList, { clientSlug: clientSlug }, { replace: isNavigationByApp });
    },
    [clientSlug, navigateToPath, sections, shouldSkipSectionList],
  );

  /**
   * Returns whether the current location is on any of the specified `routes`
   */
  const isAppOnRoute = useCallback(
    (...routes: string[]): boolean => {
      return routes.some((r) => !!matchPath(r, location.pathname));
    },
    [location.pathname],
  );

  const navigateToStart = useCallback(() => {
    if (isKiosk) {
      switch (runtimeMode) {
        case 'scanAndGoInAppPayment':
        case 'scanAndGoRelayPayment':
          navigateToPath(routes.kioskScreensaver, { clientSlug });
          break;
        case 'regular':
        case null:
          if (hasEatIn || hasTakeOutRegular || hasTakeOutCustom) {
            navigateToPath(routes.start, { clientSlug });
          } else if (hasBuyNowTakeLater) {
            navigateToPath(routes.nowOrLater, { clientSlug });
          } else {
            navigateToProducts();
          }
          break;
        default:
          throw new Error('Unknown kiosk runtimeMode');
      }
      return;
    }

    // non-kiosk:
    switch (runtimeMode) {
      case 'scanAndGoRelayPayment':
      case 'scanAndGoInAppPayment':
        navigateToPath(routes.scanAndGoMobileCart, { clientSlug });
        break;
      case 'regular':
      case null:
        navigateToPath(routes.start, { clientSlug });
        break;
      default:
        throw new Error('Unknown nonkiosk runtimeMode');
    }
  }, [
    clientSlug,
    hasBuyNowTakeLater,
    hasEatIn,
    hasTakeOutCustom,
    hasTakeOutRegular,
    isKiosk,
    navigateToPath,
    navigateToProducts,
    runtimeMode,
  ]);

  return {
    isAppOnRoute,
    generatePathWithMode,
    hasMultipleSections,
    navigateToPath,
    navigateToProducts,
    shouldSkipSectionList,
    navigateToStart,
  };
};
