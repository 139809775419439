import { t } from 'i18next';

import { Api } from '~/api-client/api';
import type { Kiosk } from '~/hooks/useAppContext/appContext.schema';
import { PreparationChoice } from '~/hooks/useInventory/Inventory.typings';
import { sumValuesByKey } from '~/utils/convert.util';
import { formatCurrency, formatDate, formatMoney } from '~/utils/format.util';

import { ReceiptCollectionModel, ReceiptLineModel, ReceiptModel } from './ReceiptModeling';

/**
 * Options for detail adjustments to the receipt building.
 * Introduced because during creation of this ReceiptBuilder, it became apparent that our receipt types (pdf/onscreen/epos)
 * are slightly different in some aspects.
 */
export interface BuildOptions {
  /** if true: an 'x' is appended to the number of products, e.g. "2x" */
  appendXtoCount: boolean;
  /** if true: amounts are prepended with a currency symbol, e.g. "€ 4,95" */
  prependCurrencySymbol: boolean;
  /** if true: the product unit price is left blank in case the product count is 1 */
  omitRedundantUnitPrice: boolean;
}

/**
 * Populates the `ReceiptModel`.
 * Introduced for separating the assembling of receipt data (this class) from the onscreen/pdf/epos output generated from that data (elsewhere).
 * Applies BuilderPattern.
 */
export class ReceiptBuilder {
  // #region private fields

  private readonly _preparationChoicesById: Record<number, PreparationChoice>;
  private readonly _userLanguage: string;
  private readonly _clientName: string | null;
  private readonly _logo: Omit<Api.ImageData, 'id'> | null;
  private readonly _takeOutCustomName: Record<string, string> | null;
  private readonly _isKiosk: boolean;
  private readonly _selectedKiosk: Kiosk | null;
  private readonly _buildOptions: BuildOptions;

  // #endregion

  // #region constructors

  /**  Constructor, for injecting contextual (order aspecific) dependencies */
  public constructor(
    preparationChoicesById: Record<number, PreparationChoice>,
    language: string,
    clientName: string | null,
    logo: Omit<Api.ImageData, 'id'> | null,
    takeOutCustomName: Record<string, string> | null,
    isKiosk: boolean,
    selectedKiosk: Kiosk | null,
    buildOptions: BuildOptions,
  ) {
    this._preparationChoicesById = preparationChoicesById;
    this._userLanguage = language;
    this._clientName = clientName;
    this._logo = logo;
    this._takeOutCustomName = takeOutCustomName;
    this._selectedKiosk = selectedKiosk;
    this._isKiosk = isKiosk;
    this._buildOptions = buildOptions;
  }

  // #endregion

  // #region public methods

  public run(orderSet: Api.FinalizedOrderSet): ReceiptCollectionModel {
    // precondition check
    if (!orderSet.finalizedOrders || orderSet.finalizedOrders.length === 0)
      throw new Error('No orders found to build a receipt for');

    // level 0: root level
    const receiptCollection = this.buildReceiptCollection(orderSet);

    // level 1: orders within the orderset
    orderSet.finalizedOrders.forEach((order) => {
      const receipt = this.buildReceipt(order);
      receiptCollection.receipts.push(receipt);

      // level 2: orderlines within an order
      order.productGrouping?.forEach((orderLine) => {
        orderLine?.products?.forEach((product, productIndex) => {
          receipt.receiptLines.push(
            this.buildReceiptLine(orderLine.count, orderLine.menu, product, productIndex === 0),
          );
        });
      });
    });

    return receiptCollection;
  }

  // #endregion

  // #region private main methods

  /** Build level 0: ReceiptCollection  */
  private buildReceiptCollection(orderSet: Api.FinalizedOrderSet): ReceiptCollectionModel {
    const deposit: number = orderSet.finalizedOrders!.reduce((acc, curr) => acc + curr.totalDeposit, 0);

    const receiptCollection = {
      receipts: [],
      aggregatedDeposit: deposit
        ? { label: t('checkout.totals.deposit'), amountText: this.formatAmount(deposit) }
        : null,
      aggregatedGrandTotal: { label: t('checkout.totals.total'), amountText: this.formatAmount(orderSet.totalPrice) },
      aggregatedVats: this.buildVats(orderSet.finalizedOrders!.flatMap((o) => o.vats ?? [])),
    };

    return receiptCollection;
  }

  /** Build level 1: ReceiptCollection → Receipt */
  private buildReceipt(order: Api.FinalizedOrder): ReceiptModel {
    const receipt = {
      logo: this._logo,
      title: this._clientName,
      orderNumberText: this.getOrderNumberText(order.delivery?.eatInReference, order.orderNumber),
      orderDescription: this.getOrderDescription(order.delivery?.eatInReference),
      nowOrLaterText: this._isKiosk ? t(`now-or-later.${order.timingOption}`) : null,
      paymentMethodText: this._isKiosk
        ? t('checkout.receipt.payment-method.kiosk')
        : t('checkout.receipt.payment-method.non-kiosk'),
      orderDateText: formatDate(order.date),
      orderTypeText: this.getOrderTypeText(order.orderType),
      deliveryTimeslotText: order.delivery?.timeSlot ?? null,
      pickupOutletName: this.getPickupOutletName(),
      deposit: order.totalDeposit
        ? { label: t('checkout.totals.deposit'), amountText: this.formatAmount(order.totalDeposit) }
        : null,
      grandTotal: { label: t('checkout.totals.sub-total'), amountText: this.formatAmount(order.totalToPay) },
      vats: this.buildVats(order.vats ?? []),

      receiptLines: [],
    };

    return receipt;
  }

  /** Build level 2: ReceiptCollection → Receipt → ReceiptLine */
  private buildReceiptLine(
    lineItemCount: number,
    lineItemMenu: Api.MenuInfo | null,
    product: Api.FinalizedProductLine,
    isFirstProduct: boolean,
  ): ReceiptLineModel {
    const targetModel = {} as ReceiptLineModel;

    // supporting variables
    const isForMenu = !!lineItemMenu;
    const isMainProduct = isForMenu ? isFirstProduct : true;
    const orderedCount = isForMenu && lineItemCount > 0 ? lineItemCount : product.count;

    // set countText
    targetModel.countText = isMainProduct ? `${orderedCount}${this._buildOptions.appendXtoCount ? 'x' : ''}` : '';

    // set description
    targetModel.description = isForMenu && isMainProduct ? lineItemMenu.name! : product.name!;

    // set unitPriceText
    const unitPrice = isForMenu
      ? isMainProduct
        ? lineItemMenu.price
        : product.extraPrice ?? 0
      : product.discountedPrice ?? product.price;

    const omitUnitPrice = unitPrice === 0 || (this._buildOptions.omitRedundantUnitPrice && orderedCount === 1);
    targetModel.unitPriceText = omitUnitPrice ? null : this.formatAmount(unitPrice);

    // set subtotalPriceText
    targetModel.subtotalPriceText = this.formatAmount(unitPrice * orderedCount);

    // set preparationChoices
    targetModel.preparationTexts = this.getPreparationChoices(product);

    // set excludedIngredientsText
    const excludedIngredients = this.getExcludedIngredients(product);
    targetModel.excludedIngredientsText = excludedIngredients.length
      ? `${t('cart.without')}: ${excludedIngredients.map((ingr) => ingr.name).join(', ')}`
      : null;

    // set supplements
    const supplements = this.getSupplements(product);
    targetModel.supplements = supplements.map((sup) => ({
      description: sup.name!,
      extraPriceText: !sup.extraPrice
        ? ''
        : this._buildOptions.omitRedundantUnitPrice
          ? sup.count > 1
            ? this.formatAmount(sup.extraPrice)
            : ''
          : this.formatAmount(sup.extraPrice),
      subtotalPriceText: sup.extraPrice ? this.formatAmount(sup.extraPrice * orderedCount) : '',
    }));

    return targetModel;
  }

  // #endregion

  // #region private supporting methods

  private buildVats(vats: Api.CalculatedVAT[]): { label: string; amountText: string }[] {
    const totalVatsByPercentage = sumValuesByKey(
      vats.sort((a, b) => a.percentage - b.percentage),
      (x) => x.percentage.toString(),
      (x) => x.amount,
    );

    return Object.keys(totalVatsByPercentage).map((percentage) => ({
      label: `${t('checkout.totals.vat')} ${percentage}%`,
      amountText: `${this.formatAmount(totalVatsByPercentage[percentage])}`,
    }));
  }

  private formatAmount(rawAmount: number): string {
    return this._buildOptions.prependCurrencySymbol ? formatCurrency(rawAmount) : formatMoney(rawAmount);
  }

  /** Only applicable for kiosks: text indicating where to pick-up the ordered products */
  private getPickupOutletName(): string | null {
    if (!this._isKiosk) return null;

    if (!this._selectedKiosk) return t('service.general.no-outlet');

    return t('checkout.receipt.pickup-at', {
      outlet: this._selectedKiosk.nameOnReceipt || this._selectedKiosk.name,
    });
  }

  /** Humanfriendly text for either 'isEatIn', 'isTakeAway', 'isHomeDelivery' or 'isTakeOutForTheRoad' */
  private getOrderTypeText(orderType: Api.OrderType): string {
    // for takeOutCustom, the custom name prevails over the fallback name from POEditor
    if (orderType === 'isTakeOutForTheRoad' && this._takeOutCustomName?.[this._userLanguage]) {
      return this._takeOutCustomName[this._userLanguage];
    }

    const translationKeySuffix = {
      isEatIn: 'eat-in',
      isHomeDelivery: 'delivery',
      isTakeAway: 'take-away',
      isTakeOutForTheRoad: 'take-away', // fallback for if `takeOutCustomName` is absent
    };

    // Take away can have a different translation for kiosk and non-kiosk, which is why we need to check for `isKiosk`
    return t(
      `${this._isKiosk || orderType === 'isEatIn' ? 'eat-in-take-away' : 'pick-up-delivery'}.${translationKeySuffix[orderType]}`,
    );
  }

  private getOrderDescription(eatInReference: Api.EatInReferenceData | null | undefined): string | null {
    if (!eatInReference) return null;

    switch (eatInReference.type) {
      case 'UserInput':
        return t('checkout.receipt.eat-in-reference.user-input', { userInput: eatInReference.value });
      case 'TableNumber':
        return t('checkout.receipt.eat-in-reference.table-number', { tableNumber: eatInReference.value });
      case 'TokenNumber':
        return t('checkout.receipt.eat-in-reference.token-number', { tokenNumber: eatInReference.value });
      default:
        throw new Error('Unknown eatInReference type');
    }
  }

  private getOrderNumberText(eatInReference: Api.EatInReferenceData | null | undefined, orderNumber: string): string {
    if (eatInReference) return t('checkout.success.ordernr', { orderNumber });
    else return t('checkout.success.order') + ' #' + orderNumber;
  }

  private getPreparationChoices(product: Api.FinalizedProductLine): string[] {
    if (!product.preparationChoices) return [];

    return product.preparationChoices.map((choice) => {
      const choiceGroup = this._preparationChoicesById[choice.takenFromPreparationChoiceId]?.name ?? '';
      return `${choiceGroup}: ${choice.name}`;
    });
  }

  private getExcludedIngredients(product: Api.FinalizedProductLine): Api.FinalizedSupplementLine[] {
    if (!product.supplements) return [];
    return product.supplements.filter((sup) => sup.count === -1); // count === -1 signifies an excluded ingredient
  }

  private getSupplements(product: Api.FinalizedProductLine): Api.FinalizedSupplementLine[] {
    if (!product.supplements) return [];
    return product.supplements.filter((sup) => sup.count !== -1); // count !== -1 signifies a supplement
  }

  // #endregion
}
