import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { Api } from '~/api-client';
import { env } from '~/env';
import { IndexedDbRepository } from '~/utils/IndexedDbRepository';

import useAndroidHost from './useAndroidHost';
import { useAppContext } from './useAppContext';
import { uuid } from './useCart/parseProduct.util';
import { useNetworkState } from './useNetworkState.hook';

/** signature of an orderset (expectedly to contain 1..2 orders (now/later)) that is processed via offline (local) submit & payment */
export type OfflineOrderSet = {
  /** unique identifier by which this orderset is known locally (not by the API) */
  localId: string;
  /** the orders that are submitted locally, payed locally and eventually online submitted */
  orders: Api.Order[];
  /** tracking of local payment flow */
  localPayment: {
    status: 'pending' | 'inprogress' | 'succeeded' | 'failed';
    failureMessage: string | null;
  };
  /** tracking of online submitting (once API can be reached again) */
  onlineSubmitting: {
    status: 'pending' | 'succeeded' | 'failed';
    failureMessage: string | null;
  };
};

/*
  A hook for implementation of offline ordering use cases, such as:
  - offline order submit
  - local payment
  - online submitting once API can be reached again.
*/
export const useOfflineOrdering = () => {
  const { isAPKDevice } = useAndroidHost();
  const networkState = useNetworkState();
  const { enqueueSnackbar } = useSnackbar();

  const {
    appContext: {
      device: { isKiosk },
    },
  } = useAppContext();

  /** for simulating offline ordering flow, even if API is reachable (dev/test aid) */
  const forceOfflineOrderingFlow = () => {
    sessionStorage.setItem('forceOfflineOrderingFlow', 'true');
  };

  /**
   * Single point of truth regarding the decision whether 12SO frontend is to be running the offline ordering flow
   * @todo: for now: will never return true in Staging/Prod environments, to be on the safe side.
   */
  const isOfflineOrderingFlowActive =
    env.isDevelopment &&
    isKiosk &&
    (networkState === 'offline' || sessionStorage.getItem('forceOfflineOrderingFlow') === 'true');

  /**
   * instruct APK to send payment to PINterminal directly (not via 12SO api)
   * @todo: placeholder method, needs to be implemented as part of TSO-637
   */
  const payOffline = useCallback(
    async (offlineOrderSet: OfflineOrderSet) => {
      // eslint-disable-next-line no-console, security-node/detect-crlf
      console.log('payOffline, offlineOrderSet:', offlineOrderSet);

      // precondition checks
      if (!isAPKDevice)
        // eslint-disable-next-line no-console
        console.error('offline payment is only supported on APK devices (TODO: replace by throwing an error)');

      if (['pending', 'failed'].includes(offlineOrderSet.localPayment.status) === false)
        throw new Error(`Cannot start localpayment as localPayment.status = '${offlineOrderSet.localPayment.status}'`);

      // TODO:
      // 1: update offlineOrderSet.localPayment.status to 'inprogress'
      // 2a: construct a payment message from the orderset
      //  b: send the payment message to a new method on hook useAndroidHost
      //  c: update its localPayment.status to 'succeeded' or 'failed' (depending on the outcome; if failed, store the failure message)

      // dummy promise to satisfy the return type
      return new Promise<void>((resolve) => {
        resolve();
      });
    },
    [isAPKDevice],
  );

  /** submit orders locally to an IndexedDb store, and subsequently start local payment */
  const submitOrderOffline = useCallback(
    async (orders: Api.Order[]): Promise<void> => {
      enqueueSnackbar('tmp dev notification: offline order submit. See console + IndexedDb', { variant: 'info' });

      // spawn a new offline orderset
      const offlineOrderSet: OfflineOrderSet = {
        localId: uuid(),
        orders,
        onlineSubmitting: { status: 'pending', failureMessage: null },
        localPayment: { status: 'pending', failureMessage: null },
      };

      // add the orderset to the IndexedDb store
      try {
        const offlineOrdersRepo = new IndexedDbRepository<OfflineOrderSet>('offlineBufferDb', 'orderSets', 'localId');
        await offlineOrdersRepo.add(offlineOrderSet);
      } catch (err) {
        // note: we're offline, so error won't be sent to Sentry until we're back online
        Sentry.captureException(err);
        throw new Error(
          `Failed to locally store the following order: ${JSON.stringify(offlineOrderSet)}.\nInner error: ${err}`,
        );
      }

      // initiate payment
      await payOffline(offlineOrderSet);
    },
    [enqueueSnackbar, payOffline],
  );

  /**
   * To be invoked once API can be reached again, to submit orders that were previously submitted offline
   * @todo: placeholder method, needs to be implemented as part of TSO-570.
   */
  const submitOfflineOrdersToApi = useCallback(async () => {
    // precondition check
    if (isOfflineOrderingFlowActive) throw new Error('Cannot submit offline orders to API while offline');

    // TODO
    // 1: fetch all ordersets from the IndexedDb store having onlineSubmitting.status 'pending' or 'failed'
    // 2: for each orderset:
    //    a: submit the orderset to the 12SO-API
    //    b: update its onlineSubmitting.status to 'failed' or 'succeeded' (depending on the outcome; if failed, store the failure message)

    // dummy promise to satisfy the return type
    return new Promise<void>((resolve) => {
      resolve();
    });
  }, [isOfflineOrderingFlowActive]);

  return {
    forceOfflineOrderingFlow,
    isOfflineOrderingFlowActive,
    submitOrderOffline,
    payOffline,
    submitOfflineOrdersToApi,
  };
};
