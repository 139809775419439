import { RouteObject } from 'react-router-dom';

import ContextsProvider from './hooks/ContextsProvider';
import ErrorPage from './pages/ErrorPage';
import routes from './routes';
import { sentryCreateBrowserRouter } from './sentry';

const serviceRoutes: RouteObject[] = [
  { path: routes.kioskServiceExit, lazy: () => import('./pages/kiosk/service/KioskServiceExit') },
  {
    lazy: () => import('./pages/layouts/KioskServiceMenuLayout'),
    children: [
      {
        path: routes.kioskServiceUnlock,
        lazy: () => import('./pages/kiosk/service/KioskServiceUnlock'),
      },
      {
        path: routes.kioskServiceGeneral,
        lazy: () => import('./pages/kiosk/service/KioskServiceGeneral'),
      },
      {
        path: routes.kioskPrintHistory,
        lazy: () => import('./pages/kiosk/service/KioskServicePrintHistory'),
      },
      // Not for phase 1
      // {
      //   path: routes.kioskServiceServiceStatus,
      //   lazy: () => import('./pages/kiosk/service/KioskServiceStatus'),
      // },
    ],
  },
  {
    path: routes.kioskSelect,
    lazy: () => import('./pages/kiosk/KioskSelect'),
  },
];

const kioskOnlyRoutes: RouteObject[] = [
  {
    path: routes.kioskExit,
    lazy: () => import('./pages/kiosk/KioskExit'),
  },
  {
    path: routes.kioskScreensaver,
    lazy: () => import('./pages/kiosk/screensaver/KioskScreensaver.tsx'),
  },
  {
    path: routes.blocked,
    lazy: () => import('./pages/BlockedPage'),
  },
];

const nonKioskOnlyRoutes: RouteObject[] = [
  {
    path: routes.qrScanAndGo,
    lazy: () => import('./pages/nonKiosk/redirects/Qr'),
  },
  {
    path: routes.qrTableNumber,
    lazy: () => import('./pages/nonKiosk/redirects/Qr'),
    children: [
      {
        path: routes.qrTableTentWelcome,
        lazy: () => import('./pages/nonKiosk/QrTableTentWelcome.tsx'),
      },
    ],
  },
  {
    path: routes.qr,
    lazy: () => import('./pages/nonKiosk/redirects/Qr'),
  },
  {
    path: routes.checkoutRedirectEvaluate,
    lazy: () => import('./pages/nonKiosk/CheckoutRedirectEvaluate'),
  },
  {
    path: routes.mobileCheckoutSuccess,
    lazy: () => import('./pages/nonKiosk/CheckoutSuccess'),
  },
  {
    path: routes.pickupOrDeliveryInfo,
    lazy: () => import('./pages/nonKiosk/PickupOrDeliveryInfo'),
  },
  {
    path: routes.payment,
    lazy: () => import('./pages/nonKiosk/PaymentPsp'),
  },
  {
    path: routes.pspIssuer,
    lazy: () => import('./pages/nonKiosk/PaymentIssuer'),
  },
  {
    path: routes.checkoutStart,
    lazy: () => import('./pages/nonKiosk/CheckoutStart'),
  },
];

const scanAndGoRoutes: RouteObject[] = [
  {
    path: routes.scanAndGoMobileProductScanner,
    lazy: () => import('./pages/scanAndGo/MobileProductScanner.tsx'),
  },
  {
    path: routes.scanAndGoMobileCart,
    lazy: () => import('./pages/scanAndGo/MobileCart.tsx'),
  },
  {
    path: routes.scanAndGoMobileInAppPayment, // reuse the nonKiosk checkout page
    lazy: () => import('./pages/nonKiosk/CheckoutStart'),
  },
  {
    path: routes.scanAndGoMobileRelayPaymentStart,
    lazy: () => import('./pages/scanAndGo/MobileRelayPaymentStart.tsx'),
  },
  {
    path: routes.scanAndGoKioskRelayPaymentCart,
    lazy: () => import('./pages/scanAndGo/KioskRelayPaymentCart.tsx'),
  },
  {
    path: routes.scanAndGoKioskInAppPaymentCart,
    lazy: () => import('./pages/scanAndGo/KioskInAppPaymentCart.tsx'),
  },
];

/**
 * Routes applicable to both kiosk and non-kiosk.
 * @see scanAndGoRoutes for routes applicable to scan&go
 */
const kioskNonKioskSharedRoutes: RouteObject[] = [
  {
    path: routes.root,
    lazy: () => import('./pages/Root'),
  },
  {
    path: routes.cart,
    lazy: () => import('./pages/Cart'),
  },
  {
    lazy: () => import('./pages/layouts/MainNavLayout'),
    children: [
      {
        path: routes.section,
        lazy: () => import('./pages/Section'),
      },
      {
        path: routes.sectionList,
        lazy: () => import('./pages/SectionList'),
      },
    ],
  },
  {
    path: routes.checkoutAssign,
    lazy: () => import('./pages/CheckoutAssign'),
  },
  {
    lazy: () => import('./pages/layouts/CustomizableProductLayout'),
    children: [
      {
        path: routes.customizableProduct,
        lazy: () => import('./pages/CustomizableProduct'),
      },
      {
        path: routes.menu,
        lazy: () => import('./pages/Menu'),
      },
    ],
  },
  {
    path: routes.exit,
    lazy: () => import('./pages/Exit'),
  },
];

const startRoutes: RouteObject[] = [
  {
    path: routes.start,
    lazy: () => import('./pages/Start'),
  },
  {
    path: routes.nowOrLater,
    lazy: () => import('./pages/NowOrLater'),
  },
];

const router = sentryCreateBrowserRouter([
  {
    element: <ContextsProvider />,
    errorElement: (
      <ErrorPage
        error={{ type: 'generic' }}
        resetErrorBoundary={() => {
          window.location.pathname = routes.root;
        }}
      />
    ),
    children: [
      {
        lazy: () => import('./pages/layouts/ServiceLayout'),
        children: serviceRoutes,
      },
      {
        lazy: () => import('./pages/layouts/ScanAndGoLayout'),
        children: scanAndGoRoutes,
      },
      {
        lazy: () => import('./pages/layouts/KioskLayout.tsx'),
        children: kioskOnlyRoutes,
      },
      {
        lazy: () => import('./pages/layouts/NonKioskLayout'),
        children: nonKioskOnlyRoutes,
      },
      {
        lazy: () => import('./pages/layouts/StartLayout'),
        children: startRoutes,
      },
      {
        lazy: () => import('./pages/layouts/MainLayout'),
        children: [
          // SHARED ROUTES
          ...kioskNonKioskSharedRoutes,
          // END OF SHARED ROUTES
        ],
      },
      {
        path: routes.kioskSignIn,
        lazy: () => import('./pages/nonKiosk/KioskSignIn'),
      },
      {
        path: routes.error,
        lazy: () => import('./pages/Error'),
      },
      {
        path: '*',
        lazy: () => import('./pages/NotFound'),
      },
    ],
  },
]);

export default router;
