import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { Api, apiHooks } from '~/api-client';
import { env, secApplicationId } from '~/env';
import { fallbackLng } from '~/i18n';
import { formatError } from '~/utils/errorHandling.util';

import { useAppContext } from '../useAppContext';
import { useCart } from '../useCart';
import { emptyInventory } from './emptyInventory.constant';
import { InventoryContextContext } from './Inventory';
import { Inventory } from './Inventory.typings';
import parseInventory from './parseInventory.util';

/**
 * InventoryContextProvider, used to provide and set the inventory context
 */
export const InventoryContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    appContext: { client, device },
  } = useAppContext();
  const { i18n } = useTranslation();
  const { validateCart } = useCart();
  const { showBoundary } = useErrorBoundary();
  const [inventory, setInventory] = useState<Inventory>(emptyInventory);
  const [isInventoryLoaded, setIsInventoryLoaded] = useState(false);

  const { data: apiResponseData, ...requestState } = apiHooks.useGetInventoryAll(
    {
      headers: {
        secApplicationId,
        deviceId: device.deviceId ?? undefined, // Used in kiosk mode
        clientGuid: client.clientGuid ?? undefined, // Used in non-kiosk mode
        culture: i18n.language === 'cimode' ? fallbackLng : i18n.language,
      },
    },
    {
      enabled: device.deviceId !== null || (client.clientGuid !== null && client.clientGuid !== ''),
      queryKeyHashFn: () => `getInventoryAll-${client.clientSlug}`,
    },
  );

  /**
   * For development purposes only: tweak the inventory to simulate different scenarios without
   * needing to make modifications in the Backoffice that could affect other users.
   * Do not commit your tweaks to git though.
   */
  const applyDevTweaks = useCallback((inventory: Inventory) => {
    if (!inventory) return;

    /* Just an example of what you could do here (inventory of kiosk-thesio):
      // Buffalo Wings (5000230) is in use for both Product and Menu
      const buffaloWings = inventory.productsById[5000230];
      buffaloWings.hasStock = true;
      buffaloWings.remainingStockCount = 2;

    // mainproduct "Heineken 0% Tap"
    const heineken0PercentTap = inventory.productsById[5000218];
    heineken0PercentTap.hasStock = true;
    heineken0PercentTap.remainingStockCount = 3;
    // 3 childproducts for "HEINEKEN" (5000216)
    const heinekenKlein = inventory.productsById[5000240];
    heinekenKlein.hasStock = true;
    heinekenKlein.remainingStockCount = 2;
    const heinekenMiddel = inventory.productsById[5000241];
    heinekenMiddel.hasStock = true;
    heinekenMiddel.remainingStockCount = 1;
    const heinekenGroot = inventory.productsById[5000242];
    heinekenGroot.hasStock = true;
    heinekenGroot.remainingStockCount = 1;
  */
  }, []);

  // Overwrites the remaining stock count of a product in the inventory
  const updateProductStockCount = useCallback(
    (productId: number, remainingStockCount: number) => {
      if (!inventory) return;

      setInventory((curr) => ({
        ...curr,
        productsById: {
          ...curr.productsById,
          [productId]: {
            ...curr.productsById[productId],
            remainingStockCount,
          },
        },
      }));
    },
    [inventory],
  );

  useEffect(() => {
    if (apiResponseData) {
      const parsedInventory = parseInventory(apiResponseData);

      if (env.isDevelopment && apiResponseData) {
        applyDevTweaks(parsedInventory);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).inventory = parsedInventory;
      }

      // as the very last step, after inventory is fully hydrated: mark the inventory as loaded
      setIsInventoryLoaded(true);
      setInventory(parsedInventory);
    }
  }, [applyDevTweaks, apiResponseData]);

  // After updating the inventory, we need to validate the shopping cart for prices and for any items that are no longer available
  useEffect(() => {
    if (inventory && Object.keys(inventory.productsById).length > 0) {
      validateCart(inventory);
    }
    // only run use effect if inventory changes to avoid infinite loop between inventory and cart
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventory]);

  useEffect(() => {
    if (requestState?.error) {
      const apiError = formatError('block', requestState.error as unknown as Api.Error);
      return showBoundary(apiError);
    }
  }, [requestState.error, showBoundary]);

  return (
    <InventoryContextContext.Provider
      value={{ ...inventory, ...requestState, isInventoryLoaded, updateProductStockCount }}
    >
      {children}
    </InventoryContextContext.Provider>
  );
};

InventoryContextProvider.displayName = 'InventoryContextProvider';
